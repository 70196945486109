import { APP_ID } from '../../environment';

export const SIGNUP_SUC = `${APP_ID}SIGNUP_SUC`;
export const SIGNUP_FAIL = `${APP_ID}SIGNUP_FAIL`;

export const CHECK_PHONE_REQ = `${APP_ID}CHECK_PHONE_REQ`;
export const CHECK_PHONE_SUC = `${APP_ID}CHECK_PHONE_SUC`;
export const CHECK_PHONE_FAL = `${APP_ID}CHECK_PHONE_FAL`;

export const KEEP_CRED = `${APP_ID}KEEP_CRED`;
