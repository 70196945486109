import { PartialRootStateReg } from './reducer';

export const userCredentialsSel = (
  state: PartialRootStateReg,
): typeof state.registration.userCredentials =>
  state.registration.userCredentials;

export const userPhoneSel = (state: PartialRootStateReg): string =>
  state.registration.userCredentials &&
  state.registration.userCredentials.phone;
