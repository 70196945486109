import { redirectToApp, saveAuthToken } from '@rtt-libs/auth';
import Router from 'next/router';
import registerApi, { resendCode } from '../../api/signup';
import verifyPhoneNumber from '../../api/verifyPhoneNumber';
import { SubmissionResult, ThunkResult, ThunkSubmitResult } from '../../types';
import { conditionallyDisplayConfirmCode } from '../../utils/featureFlagsFn';
import formErrorTransform from '../../utils/formErrorTransform';
import { FormValues } from '../view/RegistrationForm';
import {
  Actions,
  keepUserCredentials,
  signUpFailed,
  signUpSuccess,
} from './actions';
import { PartialRootStateReg } from './reducer';
import { userCredentialsSel, userPhoneSel } from './selectors';

export const checkPhoneThunk = (
  values: FormValues,
): ThunkSubmitResult<Actions, PartialRootStateReg> => async (
  dispatch,
): Promise<SubmissionResult> => {
  try {
    // check the phone on API
    const { data } = await verifyPhoneNumber(values);

    // then save current user credentials
    dispatch(keepUserCredentials(values));

    // then redirect to confirm_phone
    Router.push(
      `/confirm_phone?userType=${values.userType}`,
      '/confirm_phone',
    ).then(() => conditionallyDisplayConfirmCode(data?.code));

    return undefined;
  } catch (e) {
    // display errors if occurred
    const actionDispatcher = (message: string): void => {
      dispatch(signUpFailed(message));
    };
    return formErrorTransform(e, actionDispatcher);
  }
};

export const resendCodeThunk = (): ThunkResult<
  void,
  Actions,
  PartialRootStateReg
> => async (dispatch, getState): Promise<void> => {
  try {
    const phone = userPhoneSel(getState());

    if (phone) {
      const { data } = await resendCode(phone);

      conditionallyDisplayConfirmCode(data?.code);
    }
  } catch (e) {
    const errorMessage: string =
      e?.response?.data?.errors?.code ||
      e?.response?.data?.message ||
      e.message;

    throw new Error(errorMessage);
  }
};

export const verifyCodeAndRegisterUser = (
  codeConfirm,
): ThunkSubmitResult<Actions, PartialRootStateReg> => async (
  dispatch,
  getState,
): Promise<SubmissionResult> => {
  try {
    const userCredentials = userCredentialsSel(getState());

    if (!userCredentials) {
      Router.back();
      return undefined;
    }
    const { phone, ...otherCredentials } = userCredentials;

    const { data } = await registerApi({
      ...otherCredentials,
      code: codeConfirm,
      login: phone,
    });

    dispatch(signUpSuccess(data));

    saveAuthToken(data.accessToken);

    redirectToApp(data.user.role);

    return undefined;
  } catch (e) {
    // display errors if occurred
    return formErrorTransform(e);
  }
};
