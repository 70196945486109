import {
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';

type Props = {
  activeStep: number;
  steps?: string[];
};

const stepLabels = [
  'Реєстрація',
  'Підтвердження номеру телефона',
  'Заповнення профілю',
];

const RegistrationStepper: React.FC<Props> = ({
  activeStep,
  steps = stepLabels,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Stepper
      activeStep={activeStep}
      orientation={matches ? 'vertical' : 'horizontal'}
    >
      {steps.map((label, index) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default RegistrationStepper;
