import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { Link } from '../../common/Link';
import { RegistrationUserTypes } from '../../types';

type Props = {
  currentUserType: RegistrationUserTypes;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      marginTop: theme.spacing(3),
      textAlign: 'center',
    },
  }),
);

const SwitchUserType: React.FC<Props> = ({ currentUserType }) => {
  const styles = useStyles({});

  const switchTo = currentUserType === 'rtt' ? 'distributor' : 'rtt';
  const linkSwitchDescription = switchTo === 'rtt' ? 'магазин' : "дистриб'ютор";

  return (
    <div className={styles.root}>
      <Link href="/registration/[user_type]" as={`/registration/${switchTo}`}>
        Зареєструватися як {linkSwitchDescription}
      </Link>
    </div>
  );
};

export default SwitchUserType;
