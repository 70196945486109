/* eslint-disable @typescript-eslint/camelcase */
import { AxiosResponse } from 'axios';
import { ENDPOINTS } from '../environment';
import api from './setup';

type Response = {
  phone: string;
  code?: string;
};

const verifyPhoneNumber = ({
  phone,
}: {
  phone: string;
}): Promise<AxiosResponse<Response>> =>
  api.post(ENDPOINTS.verifyPhoneNumber, {
    phone,
  });

export default verifyPhoneNumber;
