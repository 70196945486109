import { SubmissionErrors } from 'final-form';
import React from 'react';
import { useDispatch } from 'react-redux';
import { checkPhoneThunk } from '../duck/thunks';
import RegistrationForm, { FormValues } from './RegistrationForm';

type Props = {
  userType: string;
};

const RegistrationContainer: React.FC<Props> = ({ userType }) => {
  const dispatch = useDispatch();

  const onRegisterSubmit = async (
    values: FormValues,
  ): Promise<void | SubmissionErrors> => {
    return dispatch(checkPhoneThunk(values));
  };

  return <RegistrationForm userType={userType} onSubmit={onRegisterSubmit} />;
};

export default RegistrationContainer;
