import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  FormHelperText,
} from '@material-ui/core';
import React from 'react';
import { Field, Form } from 'react-final-form';
import TextFieldAdapter from '../../common/TextFieldAdapter';
import {
  required,
  composeValidators,
  isMobilePhone,
  minLength,
} from '../../utils/validators';
import { OnSubmitFinalFormType } from '../../types';

export type FormValues = {
  userType: string;
  phone: string;
  password: string;
  passwordConfirm: string;
};

type Props = {
  userType: string;
  onSubmit: OnSubmitFinalFormType<FormValues>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonMargin: {
      marginTop: theme.spacing(4),
    },
  }),
);

const validateForm = (values: FormValues): Partial<FormValues> => {
  const errors: Partial<FormValues> = {};
  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'Пароли не рівні';
  }
  return errors;
};

const RegistrationForm: React.FC<Props> = ({ userType, onSubmit }) => {
  const styles = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        phone: '+380',
        userType,
      }}
      keepDirtyOnReinitialize
      validate={validateForm}
    >
      {({ handleSubmit, submitError, submitting }): React.ReactElement => (
        <form onSubmit={handleSubmit}>
          <Field name="userType" hidden component="input" validate={required} />
          <Field
            name="phone"
            type="tel"
            component={TextFieldAdapter}
            label="Номер мобільного телефону"
            fullWidth
            validate={composeValidators(required, isMobilePhone)}
          />
          <Field
            name="password"
            type="password"
            component={TextFieldAdapter}
            label="Пароль"
            fullWidth
            validate={composeValidators(required, minLength(6))}
          />
          <Field
            name="passwordConfirm"
            type="password"
            component={TextFieldAdapter}
            label="Підтвердження паролю"
            fullWidth
            validate={required}
          />
          <FormHelperText error={!!submitError}>{submitError}</FormHelperText>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            className={styles.buttonMargin}
            disabled={submitting}
          >
            Надіслати SMS для перевірки
          </Button>
        </form>
      )}
    </Form>
  );
};

export default RegistrationForm;
