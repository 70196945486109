import { Reducer } from 'redux';
import * as TYPES from './types';
import { FormValues } from '../view/RegistrationForm';

const initialState = {
  userCredentials: null as FormValues,
};

export type RegistrationState = typeof initialState;

export type PartialRootStateReg = {
  registration: RegistrationState;
};

const reducer: Reducer<RegistrationState> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case TYPES.KEEP_CRED:
      return {
        ...state,
        userCredentials: payload,
      };
    case TYPES.SIGNUP_FAIL:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
