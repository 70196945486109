/* eslint-disable @typescript-eslint/camelcase */
import { AxiosResponse } from 'axios';
import { ENDPOINTS } from '../environment';
import api from './setup';
import { AuthResponseType } from '../types';

const registerApi = ({
  userType,
  login,
  password,
  passwordConfirm,
  code,
}): Promise<AxiosResponse<AuthResponseType>> =>
  api.post(ENDPOINTS.signup, {
    code,
    login,
    password,
    password_confirmation: passwordConfirm,
    user_type: userType,
  });

export const resendCode = (phone: string) =>
  api.post<{ code?: string }>(ENDPOINTS.verifyPhoneResend, { phone });

export default registerApi;
