import { ActionCreator } from 'redux';
import * as TYPES from './types';
import { ActionType } from '../../types';

export const signUpSuccess: ActionCreator<ActionType> = payload => ({
  type: TYPES.SIGNUP_SUC,
  payload,
});

export const signUpFailed: ActionCreator<ActionType> = (reason: string) => ({
  type: TYPES.SIGNUP_FAIL,
  payload: reason,
});

export const keepUserCredentials: ActionCreator<ActionType> = values => ({
  type: TYPES.KEEP_CRED,
  payload: values,
});

export type Actions =
  | ReturnType<typeof signUpSuccess>
  | ReturnType<typeof signUpFailed>
  | ReturnType<typeof keepUserCredentials>;
